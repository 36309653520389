import * as $ from 'jquery';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        SharedPipesModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        CommonModule,
        NgbModule.forRoot()
    ],
    providers: [AuthGuard],
    bootstrap: [AppComponent]
})
export class AppModule { }
