import { Pipe, PipeTransform } from '@angular/core';
import { ApiUrlService } from '../service/api-url.service';

@Pipe({ name: 'toImageUrl' })
export class ToImageUrlPipe implements PipeTransform {
    constructor(private api: ApiUrlService) {}
    transform(value: string) {
        return value
            ? value.includes('http://') || value.includes('https://')
                ? value
                : this.api.endpoint + 'uploads/' + value
            : '';
    }
}
