import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ApiUrlService {
    constructor() {}

    endpoint = environment.apiUrl;
    token = localStorage.getItem('accessToken');
    headers = new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.token
    });
}
