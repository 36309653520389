import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CutStringPipe } from './cut-string.pipe';
import { ToImageUrlPipe } from './to-image-url.pipe';
import { EscapeHtmlPipe } from './keep-html.pipe';
import { SummaryPipe } from './summary.pipe';
import { YoutubeThumbnailPipe } from './to-youtube-thumbnail.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [CutStringPipe, ToImageUrlPipe, EscapeHtmlPipe, SummaryPipe, YoutubeThumbnailPipe],
    exports: [CutStringPipe, ToImageUrlPipe, EscapeHtmlPipe, SummaryPipe, YoutubeThumbnailPipe]
})
export class SharedPipesModule {}
