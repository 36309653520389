import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toYoutubeThumbnail' })
export class YoutubeThumbnailPipe implements PipeTransform {
    transform(value: string) {
        const splitUrl = value ? value.split('/')[3] : '';
        const videoId = splitUrl ? splitUrl.slice(splitUrl.lastIndexOf('?') + 3) : '';
        return value ? 'https://img.youtube.com/vi/' + videoId + '/mqdefault.jpg' : '';
    }
}
