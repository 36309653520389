import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'not-found', loadChildren: './404/not-found.module#NotFoundModule' },
    { path: 'forbidden', loadChildren: './403/forbidden.module#ForbiddenModule' },
    {
        path: '',
        loadChildren: './pages/pages.module#PagesModule'
    },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false }), NgbModule.forRoot()],
    exports: [RouterModule]
})
export class AppRoutingModule {}
